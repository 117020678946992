
// Media Queries Mixins
$mob-479: 479px !default;
$tab-768: 768px !default;
$tab-max1024: 1024px !default;
$desk1200: 1200px !default;
$lap1366: 1366px !default;
$desk1440: 1441px !default;

@mixin viewport($media) {
	@if $media==desk-ultra {
		@media screen and (min-width: $desk1440) {
			@content;
		}
	}

	@else if $media==lap-top {
		@media screen and (max-width: $lap1366) {
			@content;
		}
	}

	@else if $media==desk {
		@media screen and (min-width: $desk1200) {
			@content;
		}
	}

	@else if $media==lap-max {
		@media screen and (max-width: $desk1200 - 1) {
			@content;
		}
	}

	@else if $media==tab-min {
		@media screen and (max-width: $tab-max1024) {
			@content;
		}
	}

	@else if $media==tab-only {
		@media screen and (min-width: $tab-768) and (max-width: $tab-max1024 - 1) {
			@content;
		}
	}

	@else if $media==lap-only {
		@media screen and (min-width: $tab-max1024) and (max-width: $desk1200 - 1) {
			@content;
		}
	}

	@else if $media==mob {
		@media screen and (max-width: $tab-768 - 1) {
			@content;
		}
	}

	@else if $media==xs-mob {
		@media screen and (max-width: $mob-479) {
			@content;
		}
	}

	@else if $media==tab-lap {
		@media screen and (min-width: $tab-768) and (max-width: $desk1200 - 1) {
			@content;
		}
	}

}


%highlight_underline {
	content: "";
	position: absolute;
	height: 30%;
	width: 105%;
	background-color: rgba( $primary-color,0.3);
	left: 0%;
	top: 65%;
	z-index: -1;
  }



@keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0
    }
  
    100% {
      background-position: 468px 0
    }
  }
  
  $ss-color--env: #F5F5F5;
  $ss-color--border: #EDEDED;
  
  @mixin animated_skeleton_bg($height: 20px, $width:auto) {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: $ss-color--env;
    background: linear-gradient(to right, $ss-color--env 8%, $ss-color--border 18%, $ss-color--env 33%);
    background-size: 800px 104px;
    height: $height;
    border-radius: 6px;
  
    @if $width {
      width: $width;
    }
  
    position: relative;
  }