.navigation {
    background: #fbfbfb;
    border-bottom: .1rem solid #efefef;
    display: block;
    height: 5.2rem;
    left: 0;
    max-width: 100%;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
}
svg{
    display: none !important;
}
.navigation .navigation-link, .navigation .navigation-title, .navigation .title {
    display: inline;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 5.2rem;
    padding: 0;
    text-decoration: none;
    letter-spacing:-0.1px;
}
.navigation .title {
    font-weight: 700;
}
.navigation .img {
    fill: #9b4dca;
    height: 2rem;
    position: relative;
    top: .3rem;
} 
.navigation .navigation-list {
    list-style: none;
    margin-bottom: 0;
    margin-right: 5rem;
    @media only screen and (max-width: 767px) {  
        margin-right: 0;
     }
} 
.navigation .navigation-item {
    float: left;
    margin-bottom: 0;
    margin-left: 2.5rem;
    position: relative;
}
.navigation-item{
    cursor: pointer;
    &:hover{
        .popover {
            display: block; 
        }
    }
}
.popover {
    background: #fff;
    border: .1rem solid #d1d1d1;
    border-radius: .4rem;
    display: none;
    filter: drop-shadow(0 0 .6rem rgba(0,0,0,.1));
    left: 50%;
    min-width: 13.4rem;
    position: absolute;
    top: 94%;
    transform: translateX(-50%);
   
}
.popover .popover-list {
    list-style: none;
    margin: 0;
    padding: 0;
}
.popover .popover-item {
    margin: 0;
    padding: 0;
}
.popover .popover-item:first-child .popover-link {
    border-radius: .4rem .4rem 0 0;
}
.popover .popover-link {
    border-bottom: .1rem solid #d1d1d1;
    color: #606c76;
    display: block;
    font-size: 1.2rem;
    padding: .8rem 2rem;
    font-weight: 500;
    position: relative;
    text-align: center;
    text-decoration: none;
} 

.popover:after, .popover:before {
    border: solid transparent;
    border-color: transparent;
    border-width: 1rem;
    content: " ";
    height: 0;
    left: 50%;
    pointer-events: none;
    position: absolute;
    right: 1.7rem;
    top: 0;
    width: 0;
}
.popover:before {
    border-bottom-color: #d1d1d1;
    transform: translate(-50%,-102%);
}
.popover:after {
    border-bottom-color: #fff;
    transform: translate(-50%,-100%);
}

@include viewport(mob){
    .popover{
        transform: none;
        right: 0;
        left: auto;
    }
    .popover:before,.popover:after{ 
        right: 0;
        left: auto;
    }
}