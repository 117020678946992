
.blog-container
{
    margin: 5% 0 10%;
    position: relative;
    h2,h3,h4,h5,h6{
        font-weight: 500;
    }
    h1 {
        font-size: 4.5rem;
        line-height: 1.2;
        font-weight: 700;
    }

    h2{
        font-size: 3rem;
    }
    h3{
        font-size: 2.5rem;
    }
    h4{
        font-size: 2.2rem;
    }
    h6{
        font-size: 1.8rem;
        font-weight:300; 
        
    }
    p{
           strong{
            font-weight:500;

        }
    }

    p,ul,li{
        font-size: 2.2rem;
        font-weight: 300;
        line-height:1.8;
        a{
            transition: all 0.3s;
            &:hover{
                border-bottom: thin dotted $primary-color;
            }
        }
    }
    ul,li{
        font-size: 24px;
    } 
    .center{
        margin: auto;
    }

    .title-container
    {
        text-align: center;
        margin: 5% auto 2%;
        @include viewport(mob){
            padding-top: 50px;
        }
        p{
            font-weight:300;
        }
    }

    .row{
        margin: 4% 0;
        &:first-child{
            margin:0;
        }
        @include viewport(mob){
            width: 100% !important;
         }
    }
    .title-highlight{
        position: relative;
        display: inline-block; 
        &:after{ 
            @extend %highlight_underline;
        }
    }

    .column.column-75{
        @include viewport(mob){
           width: 100%;
           max-width: 100%;
           flex: 1 !important;
        }
    }
}


.blog-list-item
{
    padding: 20px 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    h4,h5,h6,p{
        font-weight: 500;
        transition: all 0.3s;
        word-break: break-word;
        margin: 0;
        position: relative;
        display: inline-block;
        color: $text-color;
        font-size: 1.4em;
        @include viewport(mob){
            font-size: 1.2em; 
        }
    }
    p{
        font-weight: 300;
        font-size: 18px;
        min-width: 150px;
        margin-top: 5px;
    }
    border-bottom: 1px solid $border-color;
    &:last-child {
        border-bottom: 1px solid transparent;

    }
    cursor: pointer;
    transition: all 0.3s;
    &:hover{

        h4,h5,h6{
            position: relative;
            display: inline-block; 
            &:after{ 
                @extend %highlight_underline;
                @include viewport(mob){
                 display: none;
                }
            }
        }
    }
}
 
.blog-container{

    img.lazyload{
        margin: 10px 0 20px;
    }
}

.divider--top
{
    margin-top: 3em;
    padding-top: 3em;
    border-top: 1px solid $border-color;
}
.subscription-container
{
    // background-color: $border-color;
    border: 10px solid $border-color;
    padding: 3em 4em;
    @include viewport(mob){
        padding: 4em 2em;
        .row.margin--zero
        {
            display: flex;
            flex-direction: column;
        }
        form{
            .column.column-40{
                width: 100%;
                max-width: 100%;
                margin-bottom: 10px;
            } 
        }

    }
    border-radius: 9px;
    position: relative;
    &:before {
        content: "";
        right: 0;
        top: 0;
        position: absolute;
        z-index: -1;
        width: 250px;
        height: 120px;
        background-image: radial-gradient($border-color, 15%,transparent 0),radial-gradient($border-color, 15%,transparent 0);
        background-size: 20px 20px;
    }
    &:after {
        content: "";
        left: 0;
        bottom: 0;
        position: absolute;
        z-index: -1;
        width: 120px;
        height: 80px;
        background-image: radial-gradient($border-color, 15%,transparent 0),radial-gradient($border-color, 15%,transparent 0);
        background-size: 20px 20px;
    }
    .margin--zero{
        margin: 0;
    }
    h3,h4,h5{
        margin-bottom: 5px;
        font-weight: 700;
        // color: $primary-color;
    }
    p{
        line-height: 1.4;
        font-weight: 300;
        margin-bottom: 20px; 
        font-size: 1.2em;
    }
    input,button{
        height: 45px;
        width: 100%;
        margin: 0;
        border-radius: 3px;
    }
    input{
        height: 45px;
        border-radius: 3px;
        box-shadow: none; 
        outline:none;
        border: thin solid darken($border-color,5%);
        background-color: white;
        padding: 10px;
    }
    button{
        transition: all 0.3s;
        outline:none;
        box-shadow: none; 
        &:hover{
            background-color: darken($primary-color,5%);
        }
    }  
    form{
        margin:0;
    }
    .success-box{
        padding: 15px 20px 10px;
        font-size: 18px;
        font-weight: bold;
        font-weight: 700;
        color: $green;
        border-radius: 5px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom: 3px solid $green;
    }
    .error-box{
        color: $primary-color; 
        padding: 5px;
        font-size: 14px;
        font-weight: 500; 
    }

}


.mt--2em{
    margin-top: 2em;
}
.mtb--2em{
    margin-top: 2em;
    margin-bottom: 2em;
}

.related-posts-container
{
    position: relative;
    margin-top: 20px;
    h4,h2,h3{
        position: relative;
        font-weight: 700;
        // padding-bottom: 7px;
        // border-bottom: 3px dotted $border-color;
        margin:0;
        display: inline-block;
        &:after{
            @extend %highlight_underline; 
        }
    }
}


video{
    outline:none;
    border:none;
    &:active,&:focus,&:hover{
        outline:none;
        border:none;
    }
}