$border-color:  #e6e6e6;
$primary-color: #D33257;
$text-color: #222;
$text-color-content: #585858;
$green: #37a67d;
// $primary-color: #F21905;

@import './mixin.scss';
@import './normalize.scss';
@import './milligram.scss';
@import './navigation.scss';
@import './utilities.scss';
@import './blog.scss';



body{
    color:$text-color;
    // font-family:'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-family: 'Zilla Slab', serif !important;
    font-size:1.6em;
    font-weight:300;
    letter-spacing:0px;
    line-height:1.6
} 
a{
    color: $primary-color;
}
.container-maintainence
{
    padding: 15% 20%;
}
@media only screen and (max-width: 600px) { 
    .container-maintainence
    {
        padding: 40% 5%;
    }
}


.banner-wrapper
{
    margin: 12% auto 8%;
    text-align: center;
    letter-spacing: -0.3px;
    border-bottom: thin solid $border-color;
    // width: 50%;
    @media only screen and (max-width: 767px) {  
       margin: 30% auto 10%;
    //    width: 100%;
    }
    @media only screen and (max-width: 480px) {  
       margin: 25% auto 15%;
        img{
            max-width: 80%;
        }
    }
    h1{
        font-weight: bold;
        
    }
    h4,h5{
        color: $text-color-content;
        font-weight: 300;
        font-size: 1.3em;
    }
}

.category-item
{
    // border-left: 5px solid #9b4dca;
    padding: 1em 2em;
    margin: 1em auto;
    font-size:1.4em;
    @include viewport(mob){
        padding: 1em 0; 
     }
    // width: 60%;
    h5{
        font-weight: 700;
        position: relative;
        display: inline-block;
        font-size:inherit;
        color: #000;
        &:after{ 
            content: "";
            position: absolute;
            height: 30%;
            width: 110%;
            background-color: rgba( $primary-color,0.4);
            left: 0%;
            top: 63%;
            z-index: -1;
        }
    }
    p{ 
        font-weight: 300;
        font-size:inherit;
        color: $text-color-content;
    }
}

// --theme-colour: #D33257;
// --theme-colour-light: #F05377;
// --theme-colour-lighter: #EB8FA4;

.footer {
    padding: 40px 0 0 0;  
    border-top: thin solid $border-color;
    margin: 75px auto 20px;
    p{
        font-weight:300;
        font-size: 14px;
        color: $text-color-content;
    }
    &.compressed{
        margin: 25px auto 20px;
        padding: 20px 0 0 0;  
    }
}

.avatar--profile
{
    max-height: 50vh;
    transition: all 0.3s;
}

.page404{
    margin-top: 30px;
    p{
        margin-top: 1.5em;
        font-size: 1.4em;
        font-weight: 300; 
        text-align: center;
        color: $text-color-content;
        @include viewport(mob){
            padding: 1em 0; 
         }
    }
}