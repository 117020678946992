
.wrapper {
    display: block;
    overflow: hidden;
    position: relative;
    width: 100%;
} 

@media (min-width: 40rem){

    .wrapper-container {
        padding-top: 5rem;
    }
}
.text-align--center
{
    text-align: center;
}
 
.wrapper--50{
    width: 60%;
    margin: 0 auto;
    @media only screen and (max-width: 767px) {   
        width: 100%;
     }
     @media only screen and (max-width: 480px) {   
        width: 100%;
     }
}